// Insert your styling here.
.navtop {
  background-color: #131313;
  color: #fff;
  border-bottom: 1px solid #131313;
  .block {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  em {
    font-style:normal;
    color: #fff;
  }
  .region-navigation {
    .container();
  }
}
.affix {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  .navtop {
    display: none;
  }
}

.nav-container {
  .container-fluid();
  @media (min-width: @screen-lg-min) {
    .container();
  }
}

/* Phone number block header */
#block-bean-contact-top {
  .pull-left;
  a {
    color: #fff;
  }
}
#block-bean-oppettider {
  padding-right: 30px;
}
#block-commerce-cart-cart {
  border-right: 1px solid #030303;
}
.region.region-navigation {
  text-align: right;
  .block {
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
  #block-bean-open-hours {
    vertical-align: middle;
  }
  #block-bean-social-links {
    vertical-align: top;
    text-align:right;
    color: #fff;
    padding-top: 7.5px;
    .field {
      &.field-name-title-field {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 15px;
      }
      display: inline-block;
      &.field-name-field-social-links {
        .field-item {
          display: inline-block;
          font-size: 16px;
          margin-right: 15px;
          a {
            background-color: #fff;
            color: #131313;
            border-radius: 50%;
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align:center;
            vertical-align: middle;
            &.twitter {
              &:hover {
                background-color: #55acee;
                color: #fff;
              }
            }
            &.facebook {
              &:hover {
                background-color: #608cd7;
                color: #fff;
              }
            }
            &.instagram {
              &:hover {
                background-color: #517fa6;
                color: #fff;
              }
            }
            &.linkedin {
              &:hover {
                background-color: #006699;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
	.language-switcher-locale-url {
    padding-left: 15px;
		list-style: none;
    margin: 0;
		li {
			list-style: none;
			display: inline;
      &.first {
        a {

        }
      }
			a {
        margin-right: 5px;
				display:inline-block;
        color: #fff;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				&:hover, &.active {
					text-decoration: underline;
				}
			}
		}
	}
}
body.admin-menu.adminimal-menu::before {
  height: auto;
}
.navbar {
  .navbar-btn {
    margin: 0;
  }
  .navbar-header {
    position:relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .logo {
    position: absolute;
    top: 50%;
    padding-left: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @media (min-width: @grid-float-breakpoint){
      padding-left: 0;
    }
    img {
      max-width: 100px;
      @media (min-width: @grid-float-breakpoint){
        max-width: 190px;
      }
      height: auto;
    }
  }
  &.affix {

  }
  margin-bottom: 0;
  .navbar-nav {
    > li {
      > a {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  .navbar-collapse.collapse {
    //padding-top:15px;
  }
  @media (min-width: @grid-float-breakpoint){
    //height: 118px;
    &.affix {
      //height: 80px;
    }
    .nav-container {
      display: -webkit-flex;
      display: flex;
    }
    .navbar-header {
      margin-right: 70px;
    }
    .navbar-header,.navbar-brand,.navbar .navbar-nav,.navbar .navbar-nav > li {
      float: none;
      display: inline-block;
    }
    .region.region-navigation {

    }
    .navbar-collapse.collapse {
      width: 100%;
      clear: none;
      display: inline-block;

    }
    .navbar-nav {
      .pull-right;
      > li {
        > a {
          @media (min-width: @screen-lg-min) {

          }
          line-height: 40px;
          &.active, &:hover {
            /*-webkit-box-shadow: inset 0 -5px 0 0 @brand-success;
            -moz-box-shadow: inset 0 -5px 0 0 @brand-success;
            box-shadow: inset 0 -5px 0 0 @brand-success;*/
          }
        }
      }
    }
  }
}
//dropdown
#navbar {
  /*-webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.75);*/
	ul.nav li.dropdown:hover ul.dropdown-menu {
		/*display: block;*/
	}
  .dropdown-menu > li {
    background-color: #fff;
  }
  .dropdown-menu > li > a {
    padding: 12px 20px;
  }
  ul.nav li.dropdown ul.dropdown-menu {
    margin-top: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color:transparent;
    border: 0;
    padding: 0;
    @media (max-width: @grid-float-breakpoint){
	    position:relative;
	    width: 100%;
	    margin-bottom: 15px;
      //padding-left: 15px;
    }
    @media (min-width: @grid-float-breakpoint){
      border: 1px solid #ccc;
      /*&:before {
        content: "";
        display:block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent;
        margin-left: 30px;
      }*/
    }
  }

  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded ul.dropdown-menu {display:none;}
  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded:hover ul.dropdown-menu {display:block;}
  @media (min-width: @grid-float-breakpoint){
    .dropdown-menu .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
.navbar-default .navbar-toggle {
  border-color:transparent;
  &:hover,&:focus{
    background-color:transparent;
    .icon-bar {
      background-color:#888888;
    }

  }
  .icon-bar {
    width: 25px;
    height: 3px;
    background-color: black;
  }
}

.view-header-slideshow {
  .carousel-inner {
    .views-field-field-undertext {
      .container();
      position: absolute;
      top: 70%;
      left: 0px;
      right: 0px;
      text-align: center;
      @media (min-width: @screen-sm-min) {
        top: 50%;
        left: 10%;
        right: auto;
        text-align: left;
      }
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin: 0 auto;
      z-index: 10;
      color: #f9f9f9;
      .caption {
        background-color: rgba(0,0,0,.75);
        display: inline-block;
      }
      a {
        color: #f9f9f9;
        text-decoration:none;
      }
      h3 {
        font-size: 26px;
        padding: 20px 15px 10px;
        @media (min-width: @grid-float-breakpoint){
          font-size: 60px;
          padding: 30px 40px 10px;
        }
        color: @brand-primary;
        font-weight: bold;
        margin-top: 0;
        display: table;
        margin-bottom: 0;
        line-height: 1;
      }
      p {
        color: #f9f9f9;
        font-weight: 300;
        font-size: 27px;
        @media (min-width: @grid-float-breakpoint){
          font-size: 47px;
        }
        padding: 25px 40px;
        display: table;
        line-height: 1;
      }
    }
  }
  .carousel-indicators {
    li {
      background-color: #040404;
      border-color: #040404;
    }
    .active {
      background-color: @brand-primary;
      border-color:@brand-primary;
    }
  }
  .carousel-control {
    color: @brand-primary;
    width: 3%;
    &:hover {
      color: @brand-primary;
    }
    &.left,
    &.right {
      background-image: none;
    }
    .icon-prev {
      margin-left: 0;
    }
    .icon-next {
      margin-right: 0;
    }
    .icon-next:before {
      content: '\276D';
      font-size: 50px;
    }
    .icon-prev:before {
      content: '\276C';
      font-size: 50px;
    }
  }
}

#block-views-quote-request-block-1 {
  .view-empty > a {
    color: #fff;
    background: url('../images/quote.png') no-repeat 100%;
    padding-right: 20px;
  }
  .view-header > a {
    color: #fff;
    background: url('../images/quote.png') no-repeat 82%;
    span {
      margin-left: 15px;
      background: @brand-success;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .view-content, .view-footer {
    display: none;
  }
}

#block-system-user-menu {
  ul.menu {
    list-style: none;
    li {
      display: inline-block;
      > a {
        padding: 0 5px;
        color: #fff;
        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }
}
.region-sidebar-first {
  #block-system-user-menu--2 {
    ul.menu {
      li {
        > a {
          .btn;
          .btn-primary;
          margin-bottom: 15px;
        }
      }
    }
  }
}