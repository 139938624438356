// Insert your styling here.
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 78px;
}
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.alert {
  margin-top: 20px;
}

body.navbar-is-fixed-top {
  padding-top: 118px !important;
}
body.admin-menu.navbar-is-fixed-top {
  padding-top: 147px !important;
}
body.admin-menu.navbar-is-static-top {
  padding-top: 28px !important;
}

.front .main-container .block:first-child h2.block-title {
  margin-top: 0px;
}

.node-type-landing-page .main-container .block:first-child h2.block-title {
  margin-top: 20px;
}
.front .main-container .block-title {
  font-size: @font-size-h4;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 25px;
  color: #040404;
  z-index: 10;
  position: relative;
  span {
    font-weight: normal;
  }
}
.node-type-landing-page .main-container .block-title {
  font-size: @font-size-h3;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #040404;
  z-index: 10;
  position: relative;
  span {
    font-weight: normal;
  }
}
.region.region-sidebar-first {
  .block-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #6a6a6a;
    z-index: 10;
    position: relative;
  }
  .block-system {
    .menu.nav {
      a {
        min-width: 130px;
      }
    }
  }
}


.front .main-container .entity-bean,
.front .main-container .view-header {
  font-size: 16px;
  line-height: 1.7;
  p {
    font-size: 16px;
    line-height: 1.7;
  }
}

.btn {
  text-transform: uppercase;
  padding: 12px 25px;
  font-weight: bold;
  &:hover {
    background-color: @brand-secondary;
    border-color:  @brand-secondary;
    color: #fff;
  }
  &.btn-secondary {
    color: #fff;
    background-color: @brand-secondary;
    &:hover {
      background-color: #1e1e1e;
    }
  }
}

.page-header {
  font-size: 30px;
  font-weight: bold;
  border: 0;
  text-transform: uppercase;
}
.node-type-site-contact .page-header {
  margin: 70px 0 90px;
  text-align: center;
}
.node-type-tjanst #page-header,
.node-type-page #page-header {
  height: 313px;
}
.node-type-page .page-header {
  .container();
  color: #fff;
  position: relative;
  top: -60px;
  margin-top: -60px;
}
.region-header {

}
.highlighted{


}
.region-highlighted {
  .row();
  background-color: #f8f8f8;
}
.node-type-site-contact .region-highlighted {
  background-color: @brand-primary;
  padding-top: 70px;
  padding-bottom: 70px;
}
.view-header-slideshow {
  .carousel-indicators li {
    width: 15px;
    height: 15px;
  }
  .carousel-indicators .active {
    width: 17px;
    height: 17px;
  }
  .header-bg {
    height: 530px;
    .cover();
  }
  .flex-active-slide {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .flex-caption {
    .container();
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    color: #fff;
    h1 {
      font-size: 60px;
    }
  }
}
#block-views-front-block {
  .view > .view-content {
    padding-top: 15vh;
    padding-bottom: 10vh;
  }
  h1 {
    &:after {
      content: "";
      width: 40%;
      display:block;
      border-bottom: 4px solid @brand-success;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

#block-bean-contact-form {
  background-color: #1f1f1f;
  color: #eaeaea;
  padding-bottom: 120px;
  .block-title {
    text-align: center;
    text-transform: uppercase;
    color: #f7f7f7;
    font-weight:bold;
    margin-top: 0px;
    margin-bottom: 40px;
    line-height: 1;
  }
  .field.field-name-field-text {
    text-align:center;
  }
  .field.field-name-title-field {
    font-size: 18px;
    text-transform: uppercase;
    color: #b1b1b1;
    font-weight:bold;
    margin-bottom: 40px;
    line-height: 1;
  }
  overflow: hidden;
  .btn {
    .btn-block;
  }
  &:before {

  }
  .entityform {
    .grippie {
      display: none;
    }
    .form-control {
      border-color: #4d4d4d;
      &:focus {
        border-color: #bdbdbd;
      }
    }
    .form-control.form-text {
      height: 50px;
      background-color: #1f1f1f;
    }
    #edit-field-contact-name {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-user;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-email {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-envelope;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-phone {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-phone;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-message {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-comments;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    textarea.form-control {
      min-height: 150px;
      background-color: #1f1f1f;
    }
  }
  .field-name-map-block {
    margin-top: 62px;
    color: #000;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
    	  -webkit-filter: grayscale(99%);
  }
}

.flexslider {
  margin: 0px;
  background: none;
  border: none;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.flex-direction-nav a:before {
  line-height: 40px;
  color: rgba(248, 181, 0, 0.8);
}
.flex-control-nav {
  bottom: 10px;
  z-index:5;
}
.flex-control-paging li a {
  /*width: 20px;
  height: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;*/
  background: #f6f6f6;
  background: rgba(246,246,246,1);
}
.flex-control-paging li a.flex-active {
  background: @brand-primary;
  background: rgba(248,181,0,1);
  -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.7);
  -moz-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.7);
  -o-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.7);
}



.view.view-poolemployees {
  .view-header {
    text-align:center;
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
  }
  .view-content {
    .slides li {
      > .node {
        height: 405px;
        margin: 15px;
        overflow: hidden;
        color: #eaeaea;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;

        .group-footer {
          text-align: center;
          height: 110px;
          background-color: #1f1f1f;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 14px;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          -ms-transition: all .5s;
          -o-transition: all .5s;
           transition: all .5s;
           .field-name-title-field {
             text-transform:uppercase;
             font-weight: bold;
             margin-bottom: 15px;
           }
           .field-name-body {
             p {margin: 0;}
             a {
               font-style:italic;
               color: #b3d92f;
               text-decoration: none;
               &:hover {
                 text-decoration: underline;
               }
             }
           }
        }
      }
      &:hover {
        > .node {
          margin-top: 0;
          margin-bottom: 0;
          .group-footer {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 200px;
            background-color: @brand-success;
          }
        }
      }
    }
  }
}

.view-personal {
  .col {
    margin-bottom: 30px;
    strong {
      text-transform: uppercase;
    }
  }
}

#block-views-poolemployees-block-1 {
  color: #eaeaea;
  position:relative;
  //overflow: hidden;
  padding-top: 150px;
  padding-bottom: 150px;
  margin-top: 120px;
  margin-bottom: 150px;
  > * {
    /*-webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: skewY(7deg);
    -ms-transform: skewY(7deg);
    transform: skewY(7deg);*/
  }
  .block-title {
    text-align: center;
  }
}
.flexslider .flex-direction-nav .flex-prev,
.flexslider .flex-direction-nav .flex-next {
  outline:none;
}

#block-views-frontpage-blocks-block {
  .block-title {
    text-align:center;
    margin-bottom: 60px;
  }
  .view.view-frontpage-blocks {
    text-align:center;
    margin-bottom: 30px;
    .view-content {
      .col {
        margin-bottom: 30px;
      }
    }
  }
  .field.field-name-title-field {
    h4 {
      margin-top: 25px;
      font-weight: 700;
    }
  }
  .field.field-name-body {
    font-style:italic;
    margin-bottom: 30px;
  }
  .field.field-name-field-fp-block-image {
    display:inline-block;
    background: @brand-success;
    width: 180px;
    height: 180px;
    line-height: 180px;
    border-radius: 50%;
    text-align:center;
    img {
      display:inline-block;
    }
  }
}

#block-views-facebook-block {
  .view-facebook {
    .views-bootstrap-grid-plugin-style {
      > .row > .col {
        margin-bottom: 30px;
        > .node-teaser {
            .field-name-field-image {
              position:relative;
              z-index:1;
              &:before {
                content: "";
                position:absolute;
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                background-color: rgba(121,116,182,0.5);
                opacity: 0;
                -moz-transition: opacity 0.5s ease-in-out;
                -webkit-transition: opacity 0.5s ease-in-out;
                -ms-transition: opacity 0.5s ease-in-out;
                -o-transition: opacity 0.5s ease-in-out;
                transition: opacity 0.5s ease-in-out;
              }
            }
            &:hover {
              cursor: pointer;
              .field-name-field-image {
                &:before {
                  opacity: 1;
                }
              }
            }
          .group-footer {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

/* Share this */
.sharethis-buttons {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: right;
}
.sharethis-buttons span {
  color: #c6c6c6;
  border: 1px solid;
  border-color: #e7e7e7;
  display: inline-block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  &:hover {
    border-color: #c2c2c2;
  }
}
.st_sharethis_custom {
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: @fa-var-share-alt;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_twitter_custom {
  &:hover {
    cursor: pointer;
    color: #00cdeb;
  }
  &:before {
    content: @fa-var-twitter;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_facebook_custom {
  &:hover {
    cursor: pointer;
    color: #3B5998;
  }
  &:before {
    content: @fa-var-facebook;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_googleplus_custom {
  &:hover {
    cursor: pointer;
    color: #dd4b39;
  }
  &:before {
    content: @fa-var-google-plus;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_pinterest_custom {
  &:hover {
    cursor: pointer;
    color: #C92228;
  }
  &:before {
    content: @fa-var-pinterest-p;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}

.not-front .node .flexslider {
  position: relative;
  .flex-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #eaeaea;
    text-shadow: 1px 1px 6px rgba(104, 104, 104, 1);
    opacity: 0;
  }
  &:hover {
    .flex-caption {
      opacity: 1;
    }
  }
}

#block-menu-block-2 {
  .menu li {
    a {
      color: @text-color;
      border-top: 1px solid #e3e3e3;
      padding: 10px 0px;
      &:after {
        content: "\00BB";
        margin-left: 5px;
      }
      &:hover {
        color:#969595;
        background-color: transparent;
        &:after {
          color: @brand-success;
        }
      }
    }
  }
}

.not-front:not(.node-type-landing-page) {
  .main-container {
    > .container {
      padding-top: 60px;
    }
  }
  .region.region-sidebar-first {
    padding-bottom: 100px;
  }
  .region.region-content {
    padding-bottom: 100px;
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    color: @brand-success;
  }
}
/* Popular products */
#block-views-popular-block {
  margin-top:50px;
  margin-bottom: 30px;
 .view-popular {
   .view-content {
     .col {
       margin-bottom: 30px;
     }
   }
 }
}

/* Cart confirm message */
div.messages.commerce-add-to-cart-confirmation {
  z-index: 1001;
  padding-bottom: 0px;
  margin-bottom: 0;
  color: #838383;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: 0;
  margin-left: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 450px;
  .content {
    font-style: italic;
  }
  .button-wrapper {
     z-index: 1000;
  }
  .commerce-product-title-label,
  .commerce-product-status-label {
   float:left;
  }
  .message-inner {
    display: table;
    padding: 0 15px 10px;
  }
}
div.messages.commerce-add-to-cart-confirmation .message-inner .button-wrapper {
  padding: 25px 15px;
  background-color: #fff;
  width: auto;
}

div.messages.commerce-add-to-cart-confirmation .message-inner .button-wrapper .button {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: bold;
  &.checkout {
    background-color: @brand-primary;
    color: @brand-secondary;
    a {
      color: #fff;
      background-color: @brand-primary;
      text-decoration: none;
      border-radius: 0;
    }
  }
  &.continue {
    background-color: #f6f6f6;
    color: @brand-secondary;
    a {
      border-radius: 0;
    }
  }
}
div.messages.commerce-add-to-cart-confirmation .message-inner .added-product-title {
  width: auto;
  text-align: left;
  color: @brand-secondary;
  position: relative;
  z-index: 1001;
}

div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .views-label.views-label-quantity {
  font-size: 14px;
}
div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .views-field-commerce-total .field-content {
  color: @brand-secondary;
}

/* Facetapi Filter */

.facetapi-facetapi-checkbox-links {
  li {
    a {
      text-decoration: none;
      color: #595959;
    }
    input[type="radio"], input[type="checkbox"] {
      margin-right: 10px;
    }
  }
}
.search-api-ranges-widget {
  #edit-submit {
    visibility: hidden;
  }
  .control-label{
    display: none;
  }
  .form-control {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  input[type="text"], input[type="text"]:focus{
    outline: 0;
    border:none;
    box-shadow:none;
  }
  .form-item.form-item-range-to {
    position:relative;
    &:after {
      content: "€";
      content: "€";
      position: absolute;
      top: 7px;
      right: -10px;
    }
    .form-control {
      text-align:right;
    }
  }
  .range-slider-box {
    height: 20px;
    .ui-slider-horizontal {
      height: 4px;
      border-radius: 0;
      background:#d8d8d8;
      border: 0;
      margin:10px 0;
      &:before {
        content: "";
        background-color: #d8d8d8;
        display: block;
        height: 20px;
        width: 2px;
        position: absolute;
        top: -5px;
        left:0px;
      }
      &:after {
        content: "";
        background-color: #d8d8d8;
        display: block;
        height: 20px;
        width: 2px;
        position: absolute;
        top: -5px;
        right: 0;
      }
    }
    .ui-slider-handle {
      top: -.5em;
      margin-left: -.1em;
    }
  }
  .ui-widget-header {
    background: @brand-secondary;
  }
  .ui-slider .ui-slider-handle {
    width: 5px;
    background: @brand-secondary;
    border-color: @brand-secondary;
  }
}

.views-exposed-form {
  .views-exposed-widget {
    .btn {
		  margin-top: 0;
    }
  }
  .views-exposed-widget.views-widget-filter-search_api_views_fulltext label {

  }
  .form-control.form-checkboxes.bef-select-as-checkboxes {
	padding: 0;
  }
  .form-type-select {
    display:table;
    .control-label {
      display:table-cell;
      padding-right: 10px;
      font-weight: normal;
      color: #9a9a9a;
    }
    .form-control.form-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../images/arrow.png?v=001') no-repeat right center;
      padding: 0px 50px 0px 12px;
      height: 30px;
    }
  }
}




/* Products */

.page-display-switch {
  text-align: right;
  font-size: 20px;
  margin-bottom: 10px;
  color: #cccccc;
  a {
    color: #cccccc;
    font-size: 0;
    &:hover {
      color: #000;
    }
    &.active {
      color: #888888;
      &:hover {
        color: #000;
      }
    }
    &.page-grid-switch {
      &:before {
        font-family: FontAwesome;
        content: @fa-var-th;
        display: inline-block;
        font-size: 20px;
        position: relative;
        top: 3px;
      }
    }
    &.page-list-switch {
      &:before {
        font-family: FontAwesome;
        content: @fa-var-list;
        display: inline-block;
        font-size: 20px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.view.view-products-categorized,
.view.view-all-products-search-api.view-id-all_products_search_api.view-display-id-page_1,
.view.view-all-products-search-api.view-id-all_products_search_api.view-display-id-page_2 {
  &:not(.grid-view) {
    .views-row {
      .field-name-title-field {
        h4 {
          @media (max-width: @screen-sm-max) {
            text-overflow: ellipsis;
            max-width: 150px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .views-row {
    margin-bottom: 10px;
    > .node {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      @media (max-width: @screen-sm-max) {
        zoom: 0.8;
      }
    }
    &:hover {
      > .node {
        background-color: #f6f6f6;
      }
    }
    .field-name-field-product-image {
      img {
        max-width: 120px;
      }
    }
    .field-name-title-field {
      h4 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .field-name-commerce-price, .field-name-price-without-vat {
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }
    .group-left {
      width: auto;
      float: none;
      padding-right: 15px;
    }
    .group-middle {
      width: auto;
      float: none;
    }
    .group-right {
      text-align:left;
      float:none;
      width: auto;
      margin-left: auto;
      @media(max-width: @screen-xs-max){
        .btn {
          font-size: 0;
          .icon {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media(max-width: @screen-xs-max){
  .view.view-products-categorized,
  .view.view-all-products-search-api.view-id-all_products_search_api.view-display-id-page_2 {
    .view-content {
      &:after {
        content:"";
        display: table;
        clear: both;
      }
    }
    .views-row {
      .make-md-column(4);
      padding: 0;
      > .node {
        @media (max-width: @screen-sm-max) {
          zoom: 1;
        }
      }
      &:nth-child(3n+1){
        clear:left;
      }
      margin-bottom: 30px;
      > .node {
        display: block;
      }
      .commerce-add-to-cart > div {
        flex-direction: column;
      }
      .commerce-add-to-cart button {
        .btn-block;
      }
      .field-name-field-product-image {
        img {
          max-width: 100%;
        }
      }
      .flag-outer-quote-request {
        margin-top: 15px;
      }
      .field-name-commerce-price, .field-name-price-without-vat {
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
      }
      .group-left {
        width: auto;
        float: none;
        padding-right:0px;
      }
      .group-middle {
        width: auto;
        float: none;
      }
      .group-right {
        text-align:left;
        float:none;
        width: auto;
        @media(max-width: @screen-xs-max){
          .btn {
            font-size: 16px;
            .icon {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

}

.view.view-products-categorized.grid-view,
.view.view-all-products-search-api.view-id-all_products_search_api.view-display-id-page_2.grid-view {
  .view-content {
    &:after {
      content:"";
      display: table;
      clear: both;
    }
  }
  .views-row {
    .make-md-column(4);
    padding: 0;
    > .node {
      @media (max-width: @screen-sm-max) {
        zoom: 1;
      }
    }
    &:nth-child(3n+1){
      clear:left;
    }
    margin-bottom: 30px;
    > .node {
      display: block;
    }
    .commerce-add-to-cart > div {
      flex-direction: column;
    }
    .commerce-add-to-cart button {
      .btn-block;
    }
    .field-name-field-product-image {
      img {
        max-width: 100%;
      }
    }
    .flag-outer-quote-request {
      margin-top: 15px;
    }
    .field-name-commerce-price, .field-name-price-without-vat {
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }
    .group-left {
      width: auto;
      float: none;
      padding-right:0px;
    }
    .group-middle {
      width: auto;
      float: none;
    }
    .group-right {
      text-align:left;
      float:none;
      width: auto;
      @media(max-width: @screen-xs-max){
        .btn {
          font-size: 16px;
          .icon {
            font-size: 14px;
          }
        }
      }
    }
  }
}


.view-all-products-search-api,
.view-popular {
  .views-bootstrap-grid-plugin-style {
    > .row > .col {
      margin-bottom: 30px;
      > .node-teaser {
        a {
          text-decoration: none;
          color: #000;
        }
        -moz-transition: background-color 0.5s ease-in-out;
        -webkit-transition: background-color 0.5s ease-in-out;
        -ms-transition: background-color 0.5s ease-in-out;
        -o-transition: background-color 0.5s ease-in-out;
        transition: background-color 0.5s ease-in-out;
          .field-name-field-images a {
            display:block;
            position:relative;
            z-index:1;
          }
          &:hover {
            &:before {
            }
            cursor: pointer;
            .field-name-field-images a {

            }
          }
          .field-name-commerce-price {

          }
      }
    }
  }
}

.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-decrease a,
.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-increase a {
  border: 1px solid;
  border-radius: 0;
  border-color: #555555;
}

.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-decrease a {
  border-right: none;
}

.commerce-quantity-plusminus-link.commerce-quantity-plusminus-link-increase a {
  border-left: none;
}

.form-item-edit-quantity-0 {
  width: 92px;
  display: inline-table;
  vertical-align: baseline;
  margin-right: 5px;
  input {
    border-color: #555555;
  }
  span, input {
    display: table-cell;
  }
  #edit-edit-quantity-0 {
    max-width: 100%;
  }
  .control-label {
  text-indent: -9999px;
  }
}

.commerce-add-to-cart {
  .table-responsive {
    border: 0;

    * {
      border: 0;
    }
  }
  > div {
    display: flex;
    align-items: baseline;
    .form-wrapper {
      text-align:left;
      margin-bottom: 0;
    }
  }
  .attribute-widgets {
    text-align:left;
    margin-bottom: 30px;
  }
  .form-item-quantity {
    width: 92px;
    display: table;
    @media (min-width: @screen-md-min){
      display: inline-table;
    }
    vertical-align: baseline;
    margin-right: 5px;
    input {
      border-color: #555555;
    }
    span, input {
      display: table-cell;
    }
    #edit-quantity {
      max-width: 100%;
    }
    .control-label {
    text-indent: -9999px;
    }
  }
   .btn.btn-default,
   .btn.btn-success {
     background-color: @brand-primary;
     border-color: @brand-primary;
     &:hover {
       background-color: @brand-secondary;
       border-color: @brand-secondary;
       color: #fff;
     }
   }
}

.node-product,
.node-used-product {
  &.view-mode-full {
    .view-flexslider-thumbs {
      background-color: #f6f6f6;
    }
    .field.field-name-field-image {
      background-color: #f6f6f6;
    }
    .field-name-body {
      margin-bottom: 15px;
    }
    .field-name-commerce-price, .field-name-price-without-vat {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
      cursor: pointer;
    }
    h1 {
      margin-top: 0;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    /*.btn.btn-success {
      background-color: #fff;
      border-color: #333333;
      color: #333333;
      text-transform: none;
      font-weight: normal;
      &:hover {
        color:#fff;
        background-color: @brand-secondary;
        border-color: @brand-secondary;
      }
    }*/
    #line-item-fields-field-verse-values {
      thead .field-label {
        color: @brand-secondary;
        font-size: 17px;
      }
      tbody {
        font-style:italic;
        color: #a1a1a1;
      }
    }
  }
}

.ctools-modal-dialog .modal-body {
  .view-verse-selection {
    .row {
      margin-bottom: 30px;
    }
  }
  #edit-ervw-submit {
    position:absolute;
    top: 10px;
    right: 10px;
  }
}

#block-bean-fond-block {
  .field-name-field-text {
     margin-bottom: 30px;
    em {
      font-family: "Times New Roman", Times, serif;
      font-size: 30px;
    }
  }
}

.view-commerce-cart-form {
  .btn-info, .btn-default {
    color:#fff;
    background-color: @brand-secondary;
    border-color: @brand-secondary;
    &:hover, &:focus, &.focus {
      background-color: #1e1e1e;
      color: #fff;
    }
  }
  .btn-danger {
    background-color: #fff;
    border-color: #dddddd;
    color: #333333;
    text-transform: none;
    font-weight: normal;
    &:hover {
      color:#fff;
      background-color: @brand-secondary;
      border-color: @brand-secondary;
    }
  }
  .table {
    table-layout:fixed;
    td {
      word-wrap: break-word;
    }
  }
}

.group-greeting,
.group-cardtext {
  h3 {
    color: #3b3298;
    font-size: 17px;
    font-weight: 600;
  }
}

.view-commerce-cart-summary {
  .table {
    table-layout:fixed;
    td {
      word-wrap: break-word;
    }
  }
}
.table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
  white-space:normal;
}


/* Stacktable */

.stacktable { width: 100%; }
.st-head-row { padding-top: 1em; }
.st-head-row.st-head-row-main { font-size: 1.5em; padding-top: 0; }
.st-key { width: 49%; text-align: right; padding-right: 1%; }
.st-val { width: 49%; padding-left: 1%; }



/* RESPONSIVE EXAMPLE */

.stacktable.large-only { display: table; }
.stacktable.small-only { display: none; }
.stacktable.small-only .views-align-right {
  text-align:left;
}
@media (max-width: 800px) {
  .stacktable.large-only { display: none; }
  .stacktable.small-only { display: table; }
}

.node-image-gallery {
  .field-name-body {
    margin-bottom: 30px;
  }
  .field-name-field-images {
    height: 85px;
    margin-bottom: 15px;
  }
}


.node-page .field-name-field-link {
  margin-top: 30px;
}

/* Form */

.webform-client-form {
  margin-top: 30px;
  .webform-submit.btn.btn-primary {
    background-color: #282828;
    border-color: #282828;
    width: 100%;
  }
  .grippie {
    display:none;
  }
}

.checkout-completion-message {
  max-width: 700px;
}


/* Mail */

#mimemail-body div {
  font-size: 14px;
}


/* Customer profile shipping  */
.customer_profile_shipping {
  .field-name-field-location {
    margin-top: 30px;
  }
  .addressfield-container-inline.name-block {
    margin-bottom: 15px;
  }
}

/* Services */

.view-promo {
  .col {
    border-right: 1px solid #f5f5f5;
    &:last-child {
      border-right: 0;
    }
    .node-promo {
      padding: 30px 50px;
    }
    text-align: center;
    min-height: 260px;
    background-color: none;
    -webkit-transition: background-color 0.5s linear;
    -moz-transition: background-color 0.5s linear;
    -o-transition: background-color 0.5s linear;
    -ms-transition: background-color 0.5s linear;
    transition: background-color 0.5s linear;
    &:hover {
      background-color: @brand-primary;
      .field-name-node-link a {
        background-color: #040404;
        color: #fff;
        border-color: #040404;
      }
    }
    .field-name-title-field {

    }
    .field-name-body {
      min-height: 60px;
      max-height: 60px;
      margin-bottom: 30px;
    }
    h3 {
      text-transform: uppercase;
      color: #040404;
      font-weight: bold;
      margin-bottom: 20px;
      a {
        text-decoration: none;
        color: #040404;
      }
    }
    .field-name-node-link a {
      .btn;
      .btn-default;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        background-color: #040404;
        color: #fff;
        border-color: #040404;
      }
    }
  }
}


/* contact block */

#block-bean-contact {
  padding-left: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Open hours */

#block-bean-open-hours {
  padding-left: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
}

#block-bean-quotation {
  background-color: #f8b500;;
  padding-left: 5%;
  padding-top: 50px;
  padding-bottom: 80px;
  .field-name-field-text {
    margin-bottom: 30px;
  }
}


/* Facets */
.facetapi-facetapi-checkbox-links {
  padding-left: 0px;
}
#facetapi-facet-search-apiproduct-display-block-field-type {
  .expanded > ul {
    border: 1px solid #e6e6e6;
    padding: 10px;
    max-height: 270px;
    overflow: scroll;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
#facetapi-facet-search-apiproduct-display-block-field-categories {
  border: 1px solid #e6e6e6;
  padding: 10px;
  max-height: 270px;
  overflow: scroll;
}


/* Cart block */
.view.view-commerce-cart-block {
  .view-box {
    color: #333;
  }
}


/* BEF filters */

.form-control.form-checkboxes.bef-select-as-checkboxes {
  height: auto;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type="checkbox"], input[type="radio"] {
	display: inline;
	width: auto;
	height: auto;
	vertical-align: middle;
	line-height: 20px;
	margin: 0;
}

/* Forms */

.form-group:last-child, .panel:last-child {
  margin-bottom: 15px;
}

.street-block {
  .form-item {
    display: inline-block;
    margin-right: 15px;
  }
}


#edit-customer-profile-billing-field-telephone-und-0-value {
  max-width: 266px;
}


.webform-component {
  a {
    text-decoration: underline;
  }
}
.form-type-checkbox {
  .control-label {
    line-height: 10px;
  }
}
#sliding-popup {
  .popup-content {
    #popup-buttons button.agree-button {
      .btn;
      .btn-default;
      background-color: #131313;
      border: 1px solid #fff;
      color: #fff;
      padding: 8px 25px;
      box-shadow: none;
      text-shadow: none;
      &:hover, &:focus {
        background-color: #131313 !important;
        border-color: #131313 !important;
        color: #fff;
      }
    }
    #popup-text {
      margin-top: 20px;
      a {
        text-decoration: underline;
      }
    }
  }
}

.form-type-checkbox {
	a { text-decoration: underline; }
}


.block-taxonomy-menu-block {
  .menu.nav > .active > a {
    &,
    &:hover,
    &:focus {
      color: @dropdown-link-active-color;
      text-decoration: none;
      outline: 0;
      background-color: @dropdown-link-active-bg;
    }
   }
    .open > .dropdown-menu {
      position: relative;
      float: none;
      z-index: 1;
    }
    .dropdown > .dropdown-menu {
      border: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .dropdown-menu > li > a {
      padding: 10px 20px;
    }
    .dropdown-menu > li {
      padding-left: 5px;
    }
    .caret {
      display: none;
    }
    span.dropdown-toggle {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 14px;
      cursor: pointer;
    }
    .glyphicon-minus {
      display: none;
      font-size: 13px;
    }
    .glyphicon-plus {
      display: block;
      font-size: 13px;
    }
    .open {
      .glyphicon-minus {
        display: block;
      }
      .glyphicon-plus {
        display: none;
      }
    }

    a.active-trail {
      color: @brand-primary;
    }

  /* @media (min-width: @screen-sm-min) {
    span.dropdown-toggle {
      display: none;
    }
    .dropdown {
      position: relative;
    }
    .dropdown>.dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      margin-left: -1px;
      -webkit-border-radius: 0 6px 6px 6px;
      -moz-border-radius: 0 6px 6px;
      border-radius: 0 6px 6px 6px;
    }
    .dropdown:hover>.dropdown-menu {
        display: block;
    }
    .dropdown.pull-left {
        float: none;
    }
    .dropdown.pull-left>.dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }
    .caret {
      border-bottom: 4px solid transparent;
      border-top: 4px solid transparent;
      border-left: 4px solid;
      display: inline-block;
      height: 0;
      vertical-align: middle;
      width: 0;
    }
  } */
}

.container-inline div {
  display: inline-block;
}

/* View jump menu */
.view-jump-menu {
  .container-inline {
    > .form-item-jump {
      width: 100%;
      display: block;
    }
    .selectize-dropdown-content {
      display: block;
      div {
        display: block;
      }
    }
  }
}

.selectize-input {
  border-radius: 0;
}

.page-parts {
  .main-container {
    > .container {
      padding: 0 !important;
    }
    > #page-header {
      .page-header {
        color: @brand-primary;
        margin-bottom: 0;
      }
      height: ~"calc(100vh - 192px)";
      background: url('../images/lassas_search.jpg') no-repeat;
      background-position: center;
      .cover();
      position: relative;
    }
  }
  #search-box {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 460px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    .block-title {
      color: #fff;
    }
    label {
      color: #fff;
    }
    .selectize-input {
      padding: 12px;
      border-radius: 0;
    }
    .block {
      margin: 0 auto;
      .views-exposed-widget {
        &.views-widget-filter-type,
        &.views-widget-per-page {
          display: none;
        }
      }
    }
    #block-bean-eller {
      text-align: center;
      color: #fff;
    }
    .views-exposed-form .views-exposed-widget {
      float: none;
      padding: 0;
      &.views-widget-filter-search_api_views_fulltext label {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .views-exposed-widgets {
      display: flex;
      align-items: flex-end;
      > div {
        &:first-child {
          flex-grow: 1;
        }
        display: inline-block;
        vertical-align: bottom;
        .form-item-search-api-views-fulltext {
          margin-bottom: 0;
          input {
            padding: 22px 12px;
          }
        }
      }
      .btn-primary, .btn-default {
        background-color: @brand-primary;
        border-color: @brand-primary;
        &:hover {
          background-color: @brand-primary;
          border-color: @brand-primary;
        }
      }
    }
  }
  #block-bean-obs {
    color: #d3d3d3;
    a {
      color: #d3d3d3;
    }
  }
}

.flag-quote-request {
  .flag-throbber {
    position: absolute;
  }
  > .flag.unflag-action {
    .btn;
    .btn-danger;
    background-color: #fff;
    border-color: #dddddd;
    color: #333333;
    text-transform: none;
    font-weight: normal;
    text-decoration: none;
    &:hover, &:focus {
      background-color: #040404;
      border-color: #040404;
    }
  }
  > .flag.flag-action {
    .btn;
    .btn-primary;
    border-color: @brand-primary;
    text-decoration: none;
    &:hover, &:focus {
      background-color: #040404;
      border-color: #040404;
    }
  }
}

.grid-view {
  .flag-quote-request {
    > .flag.unflag-action,
    > .flag.flag-action {
      .btn-block;
    }
  }
}

.view-id-quote_request {
  .views-field-title {
    width: 100%;
  }
}

.webform-component--quote-request {
  .control-label {
    display: none;
  }
}

.login-notice {
  margin-top: 15px;
}

.form-type-password-confirm {
  .form-control-feedback {
    position: absolute;
  }
}

#edit-view-mode-switcher {
  text-align: right;
  .form-item {
    display: inline-block;
    margin: 0;
    .control-label {
      color: #888888;
      &.checked {
        color: #333333;
      }
    }
    &:first-child {
      .control-label {
        font-size: 0;
        input[type="radio"] {
          opacity: 0;
        }
        &:before {
          font-family: FontAwesome;
          content: @fa-var-list;
          display: inline-block;
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
      &:after {
        content: "|";
        display: inline-block;
        position: relative;
        left: 8px;
        font-size: 18px;
      }
    }
    &:last-child {
      .control-label {
        font-size: 0;
        input[type="radio"] {
          opacity: 0;
        }
        &:before {
          font-family: FontAwesome;
          content: @fa-var-th;
          display: inline-block;
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.field-type-text-long {
  span > a {
      color: inherit;
  }
}
#block-taxonomy-menu-block-1 {
  .dropdown-backdrop {
    display: none;
  }
}

@media (min-width: @screen-sm-min) {
  .region-sidebar-first.affix {
    left: ~"calc((100vw - 753px)/2)";
    max-width: ~"calc((0.25 * 768px) - 30px)";
    top: 117px;
  }
}

@media (min-width: @screen-md-min) {
  .region-sidebar-first.affix {
    left: ~"calc((100vw - 955px)/2)";
    max-width: ~"calc((0.25 * 970px) - 30px)";
  }
}

@media (min-width: @screen-lg-min) {
  .region-sidebar-first.affix {
    left: ~"calc((100vw - 1155px)/2)";
    max-width: ~"calc((0.25 * 1170px) - 30px)";
  }
}

#quicktabs-quicktabs_vehicle_type_selection {
  .nav-tabs {
    li {
      a {
        filter: invert(70%);
        -webkit-filter: invert(70%);
      }
      &.active, &:hover {
        a {
          filter: invert(0%);
          -webkit-filter: invert(0%);
        }
      }
    }
  }
}

.dumpers {
  display: block;
  text-indent: -9999px;
  background: url('../images/arthauler.png?v=0002') no-repeat center center;
  background-size: 48px 22px;
  padding: 10px 30px !important;
}

.wheel-loaders {
  display: block;
  text-indent: -9999px;
  background: url('../images/wheel-loader-tractor.png') no-repeat center center;
  background-size: 32px 32px;
  padding: 10px 30px !important;
}

.excavators {
  display: block;
  text-indent: -9999px;
  background: url('../images/construction-excavator.png') no-repeat center center;
  background-size: 32px 32px;
  padding: 10px 30px !important;
}

.including, .excluding {
  font-size: 60%;
  font-weight: normal;
}

.view-original-ref {
  margin: 15px 0;
  .views-row {
    margin: 0 !important;
  }
}

.view-passar-till {
  display: flex;
  h2 {
    font-size: @font-size-base;
    margin: 0 5px 0 0;
    line-height: @line-height-base;
    white-space: nowrap;
  }
  .view-content {
    .views-row {
      display: inline-block;
    }
  }
}

.captcha {
  margin-bottom: 15px;
}

.dropdown-backdrop {
  position: static;
}
