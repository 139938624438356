a.cart-dropdown {
    display: block;
    //background: url(/sites/all/themes/zenia/img/cart.png) no-repeat left center;
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    float: right;
}

.view-commerce-cart-block .view-header {
    text-align: right;
    overflow: auto;
    padding-right: 28px;
    .line-item-summary {
      margin: 0;
    }
    .line-item-summary .line-item-quantity {
      float:right;
      background: @brand-success;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      .line-item-quantity-label {
        display:none;
      }
    }
    .line-item-summary-view-cart {
      a {
        font-size: 0;
        display: inline-block;
        vertical-align: top;
        background: url('../images/cart.png') no-repeat right;
        width: 43px;
        height: 18px;
        &:hover {
          cursor:pointer;
        }
      }
    }
    
    .line-item-summary .links {
      float: left;
      margin: 0;
      padding: 0;
    }
}

.view-commerce-cart-block .view-box table {
    border: 0;
    > tbody > tr > td {
      border: 0;
    }
}

.view-commerce-cart-block {
  position:relative;
  .view-box {
    width: 350px;
    display: none;
    border: 1px solid #ccc;
    background: #f6f6f6;
    clear: both;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 500;
    .view-content {
      margin-left: 25px;
      margin-right: 25px;
      padding-top: 25px;
      border-bottom: 1px solid #cfcfcf;
      font-style: italic;
    }
    .view-footer {
      .line-item-quantity {
        padding-top: 15px;
        padding-left: 25px;
        padding-bottom: 25px;
      }
      .line-item-total {
        padding-top: 15px;
        padding-right: 25px;
        padding-bottom: 25px;
      }
      .line-item-summary {
        margin: 0;
      }
      .list-inline {
        display:table;
        width: 100%;
        margin: 0;
        > li {
          padding: 0;
          display:table-cell;
          width: 50%;
          text-align: center;
          &.line-item-summary-view-cart {
            a {
              display: block;
              color: @brand-secondary;
              text-decoration: none;
              width: 100%;
              padding: 15px 0;
              border-top: 1px solid #e4e4e4;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
          &.line-item-summary-checkout {
            a {
              background-color: @brand-secondary;
              color: #fff;
              text-decoration: none;
              display: block;
              width: 100%;
              padding: 15px 0;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.block-commerce-cart .cart-empty-block {
  span { 
      background: url('../images/cart.png') no-repeat left;
      display: inline-block;
      vertical-align: top;
      margin-left: 30px;
      width: 34px;
      height: 18px;
      text-align:right;
      margin-right: 30px;
  }
}
